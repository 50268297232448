<script>
import ContentsListTemplate from '../ContentsListTemplate'
import Loading from '@/components/general/Loading'
import ErrorList from '@/components/general/ErrorList'
export default {
  name: 'IndividualFinishedContents',
  components: { ContentsListTemplate, Loading, ErrorList },
  data () {
    return {
      contents: [],
      lists: [],
      loading: true,
      contentTypes: [],
      errorState: false
    }
  },
  computed: {
    mixedList () {
      return [...this.contents, ...this.lists].sort((a, b) => {
        return new Date(a.interactionDate) - new Date(b.interactionDate)
      })
    },
    availableSkills () {
      return this.$store.getters.getAvailableSkills.map(({ title, id }) => ({ text: title.length > 20 ? title.slice(0, 20) + '...' : title, value: id }))
    }
  },
  methods: {
    getContentByStatus (statusList) {
      return new Promise((resolve) => {
        statusList.forEach(status => {
          const params = {
            filter: { status },
            userId: this.$store.getters.getUser && this.$store.getters.getUser.userId
          }
          this.$store.dispatch('attemptGetFilteredUserContents', params).then((data) => {
            if (data && data.error) this.errorState = true
            else this.contents = data.data
          }).finally(() => {
            resolve(true)
          })
        })
      })
    },
    getListsByStatus (statusList) {
      return Promise.all(statusList.map(status => {
        const params = {
          filter: { status },
          userId: this.$store.getters.getUser && this.$store.getters.getUser.userId
        }
        return this.$store.dispatch('attemptGetFilteredUserLists', params).then((data) => {
          if (data && data.error) {
            this.errorState = true
          } else {
            this.contents = data.data
          }
        }).catch(error => {
          this.errorState = true
          throw error
        })
      }))
    },
    showModalAddListContent (params) {
      this.$emit('showModalAddListContent', params)
    }
  },
  created () {
    Promise.all([
      this.getContentByStatus(['conclusion']),
      this.getListsByStatus(['conclusion'])]
    ).finally(() => {
      this.loading = false
    })
    this.$store.dispatch('attemptGetContentTypes').then(data => {
      if (data && data.error) {
        this.errorState = true
      } else {
        this.contentTypes = data.data.map(contentType => {
          return { text: this.$t(`content.type:${contentType.alias}`), value: contentType.alias }
        })
      }
    })
    this.$store.dispatch('attemptGetAvailableSkills', { filterByName: this.skillSearch, limit: 999999 })
  }
}
</script>
<template>
  <div class="individual-finished--container">
    <loading v-if="loading"></loading>
    <error-list :title="this.$t('empty.state.text.published:error.text')" v-else-if="errorState && !loading"></error-list>
    <ContentsListTemplate
      v-else
      :list="mixedList"
      :availableContentTypes="contentTypes"
      :availableSkills="availableSkills"
      @showModalAddListContent="params => showModalAddListContent(params)"
      ></ContentsListTemplate>
      <router-view></router-view>
  </div>
</template>
<style lang="scss">

</style>
